import React, {useEffect} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import history from "services/history"

import DashboardLayout from "layouts/Dashboard.js";
import AuthLayout from "layouts/Auth.js";
import { useAuthContext } from "contexts/AuthContext";
import { useAppContext } from "contexts/AppContext"

const App = () => {
  const { isAuthenticated } = useAuthContext();
  const { getEnums } = useAppContext()
  
  useEffect(() => {
    getEnums();
  }, []);
  
  return (
    <>
        <Switch>
          <Route path="/dashboard" render={(props) => <DashboardLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="*" to="/dashboard/frontpage" />
        </Switch>      
    </>
  )
}

export default App;